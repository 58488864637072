@font-face {
  font-family: 'Brandon Bold';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/HVD Fonts - BrandonText-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Light';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/HVD Fonts - BrandonText-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Regular';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/HVD-Fonts-BrandonText-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Brandon Regular italic';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/HVD Fonts - BrandonText-RegularItalic.otf')
    format('opentype');
}

@font-face {
  font-family: 'Brandon Regular Thin';
  src: url('../fonts/HVD Fonts - BrandonText-Thin.otf') format('opentype');
}
